// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding  } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import {  generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc
import { ENV } from '@env/environment';
import { NavService } from '@app/shared/services/nav/nav.service';



@Component({
    selector: 'nav-zero',
    templateUrl: './nav-zero.component.html',
    styleUrls: ['./nav-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public navService:NavService
  ) { }

  classPrefix = generateClassPrefix('NavZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.navZero)
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('attr.id') myId?:string = this.idPrefix()
  ngUnsub= new Subject<void>()


  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}





