// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,   Input, ViewChild, ViewContainerRef   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';


// rxjs
import { timer, Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix, selectRandomOptionFromArray } from '@windmillcode/wml-components-base';
import {
  WMLThreeCommonObjectProps,
  WMLThreeCommonProps,
  WMLThreeLightProps,
  WMLThreeTexturesProps,
} from '@windmillcode/wml-three';


// misc

import { ENV } from '@env/environment';
import {  Color, DoubleSide, Group, IcosahedronGeometry, InstancedMesh, LatheGeometry, Matrix4, MeshPhongMaterial, MeshStandardMaterial, Object3D, OctahedronGeometry, PlaneGeometry, PointLight, TetrahedronGeometry, TorusKnotGeometry, Vector3 } from 'three';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as SkeletonUtils  from 'three/examples/jsm/utils/SkeletonUtils.js';
import { NavService } from '@app/shared/services/nav/nav.service';


@Component({
    selector: 'threejs-background-zero',
    templateUrl: './threejs-background-zero.component.html',
    styleUrls: ['./threejs-background-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ThreejsBackgroundZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public navService:NavService
  ) { }

  classPrefix = generateClassPrefix('ThreejsBackgroundZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.threejsBackgroundZero)
  @Input('props') props: ThreejsBackgroundZeroProps = new ThreejsBackgroundZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('attr.id') myId?:string = this.idPrefix()
  @ViewChild("threeZero",{read:ViewContainerRef,static:true}) threeZero :ViewContainerRef
  ngUnsub= new Subject<void>()


  listenForUpdate = ()=>{
    return this.props.setStateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new ThreejsBackgroundZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }


  ngAfterViewInit(): void {

    this.listenForUpdate().subscribe()
    this.props.threeZeroVCF = this.threeZero
    this.props.ngUnsub = this.ngUnsub
    this.initThree().subscribe()


  }

  initThree() {
    // like this because since Angular v19 in a layout and the height of the element is 0
    return timer(500)
    .pipe(
      takeUntil(this.ngUnsub),
      tap(() => {
        this.props.init();
      })
    );
    //
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



@WMLConstructorDecorator
export class ThreejsBackgroundZeroProps {
  constructor(props:Partial<ThreejsBackgroundZeroProps>={}){ }

  threeZeroVCF:ViewContainerRef
  ngUnsub= new Subject<void>()

  setStateSubj = new Subject<ThreejsBackgroundZeroProps>()
  setState = (value)=>{
    this.setStateSubj.next(value)
  }

  three!: WMLThreeCommonProps;

  init = async()=>{

    let plane = new WMLThreeCommonObjectProps({
     geometry: new PlaneGeometry(5000, 5000, 1, 1),
     material: new MeshStandardMaterial({color: 0x1A1A1A, side: DoubleSide}),

    })
    plane.makeModelLieFlat()
    plane.regularMesh.position.y = -50
    let amount = 500
    let distanceRange = 700

    let dummy = new Object3D();

    let plainObjects =[
      new IcosahedronGeometry(),
      new LatheGeometry(),
      new OctahedronGeometry(),
      new TetrahedronGeometry(),
      new TorusKnotGeometry()
    ]
    .map((geometry)=>{

      return {
        object:new WMLThreeCommonObjectProps({
          geometry,
          material: new MeshPhongMaterial({
            side: DoubleSide
          }),
          createMesh:(geometry,material)=>{
            return new InstancedMesh(geometry, material,amount)
          }
        }),
        amount:Math.max(50,Math.round(Math.random()* amount))
      }
    })

    plainObjects
    .forEach((resource,index0)=>{
      Array(resource.amount)
      .fill(null)
      .map((x,i)=>{
        dummy.position.x = Math.random() * distanceRange  * selectRandomOptionFromArray([1,-1]);
        dummy.position.y = Math.random() * distanceRange
        dummy.position.z = Math.random() * distanceRange  * selectRandomOptionFromArray([1,-1]);

        // different size needs to all have same value
        dummy.scale.x = dummy.scale.y = dummy.scale.z = Math.random()*10;

        dummy.updateMatrix();

        resource.object.instancedMesh.setMatrixAt(i, dummy.matrix);
        resource.object.instancedMesh.setColorAt(i, new Color(selectRandomOptionFromArray([0x10A37F,0x363A3D,0x000000])) );

      })
    })

    let chatGPT3Dlogo = new WMLThreeCommonObjectProps({
      texture: new WMLThreeTexturesProps({
        group: [
          {
            url: './assets/media/threejsBackgroundZero/chatgpt.glb',
            loader: new GLTFLoader(),
            onLoad: (texture: GLTF) => {
              const originalModel = texture.scene;

              for (let i = 0; i < 200; i++) {
                const clonedModel = SkeletonUtils.clone(originalModel);

                clonedModel.position.set(
                  Math.random() * distanceRange * selectRandomOptionFromArray([1, -1]),
                  Math.random() * distanceRange,
                  Math.random() * distanceRange * selectRandomOptionFromArray([1, -1])
                );


                const scale = Math.random() * 10;
                clonedModel.scale.set(scale, scale, scale);

                this.three.getCurentScene().add(clonedModel);
                chatGPT3Dlogo.meshes.push(clonedModel);
              }

            },
          },
        ],
      }),
    });


    let pointLight = new WMLThreeLightProps({
      // llike a flashlight illuminating an area
      light: new PointLight(0xffffff, 10000,0,1),
      addHelper: true,
      addShadowHelper: true,
    });
    pointLight.light.position.y = 500
    let pointLight2 = new WMLThreeLightProps({
      // llike a flashlight illuminating an area
      light: new PointLight(0xffffff, 10000,0,1),
      addHelper: true,
      addShadowHelper: true,
    });
    pointLight2.light.position.y = -500


    const matrix = new Matrix4();
    this.three = new WMLThreeCommonProps({
      rendererParentElement: this.threeZeroVCF.element.nativeElement,
      objects: [plane,chatGPT3Dlogo,...plainObjects.map((plainObject)=>plainObject.object)],
      lights:[pointLight,pointLight2],
      animateFunctions: [
        ({ clock }) => {

          plainObjects.forEach((plainObject)=>{
            let mesh = plainObject.object.instancedMesh
            Array(plainObject.amount)
            .fill(null)
            .map((x,i)=>{

              mesh.getMatrixAt(i,matrix)
              // @ts-ignore
              matrix.decompose(dummy.position, dummy.rotation, dummy.scale);

              dummy.rotation.x = clock.getElapsedTime() / 10;
              dummy.rotation.y = clock.getElapsedTime() / 5;
              dummy.rotation.z = clock.getElapsedTime() / 12;

              dummy.updateMatrix();
              mesh.setMatrixAt(i, dummy.matrix);
            })
            mesh.instanceMatrix.needsUpdate = true;
          })

          chatGPT3Dlogo.gltfMeshes.forEach((chatGPT3Dlogo,i)=>{

            let item = chatGPT3Dlogo instanceof Group ? chatGPT3Dlogo : chatGPT3Dlogo.scene
            item.rotation.x = (clock.getElapsedTime() / 10);
            item.rotation.y = ((clock.getElapsedTime()-15)/ 5);
            item.rotation.z = (clock.getElapsedTime() / 12);
          })

        },
      ],
    });

    await this.three.init({
      initInspectors:!["PROD","PREVIEW"].includes(ENV.type)
    });
    this.three.updateCameraPosition({
      // @ts-ignore
      position: new Vector3(-300, 200, -100),
      updateControls: true,
    });
  }
}


