import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { WMLInfiniteDropdownZeroProps } from '@windmillcode/angular-wml-infinite-dropdown';
import { BaseService } from '@core/base/base.service';
import { SpecificService } from '@core/specific/specific.service';
import enTranslations from "src/assets/i18n/en.json";
import { generateRandomNumber, replaceValuesWithPaths, WMLRoute } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { defer } from 'rxjs';
import { ThreejsBackgroundZeroProps } from '@app/shared/components/threejs-background-zero/threejs-background-zero.component';
import gsap from 'gsap';
import { WMLAngularMotionUIProperty } from '@windmillcode/angular-wml-components-base';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(
    public http: HttpClient,
    public utilService: UtilityService,
    public baseService: BaseService,
    public specificService:SpecificService
  ) {}

  createDesktopNavZero = () => {
    let i18nDropdown: any = replaceValuesWithPaths(
      enTranslations.NavZero.desktopOptions,
      'NavZero.desktopOptions.',
      ({key,value,path,defaultAssignment})=>{
        return new WMLRoute({
          text:defaultAssignment
        })
      }
    );
    return i18nDropdown
  }

  setActionsForDesktopNavZeroOptions = (options) => {
    options.forEach((link:WMLRoute,index0)=>{
      link.routerLink = ENV.nav.urls[["home","guide","privacyPolicyPage","termsOfServicePage","contact"][index0]]
    })
  };

  addIdsToDesktopNavZeroOptions = (options) => {

    let ids = [
      ["homeLink","guideLink","privacyPolicyLink","termsOfServiceLink"]
    ]
    options.forEach((link,index0)=>{
      link.id = ENV.idPrefix.nav+ids[index0]
    })

  };

  threeJSBackground = new ThreejsBackgroundZeroProps()
  landingPageMotionUIProperty  = new WMLAngularMotionUIProperty({
    style: {
      // styles needed for animation=
      animationDuration: '.8s'
    },
    keyFrameStyles: {
      '0%': {
        opacity:"1"
      },
      '100%': {
        opacity:"0"
      },
    }
  })
  performThreeJSNav = () => {

    return defer(()=>{
      return new Promise((resolve, reject) => {
        let  rotateCamera = (x, y, z)=> {
          gsap.to(this.threeJSBackground.three.camera.rotation, {
            x,
            y,
            z,
            duration: 3.2,
            onComplete: resolve
          })
        }
        let moveCamera = (x, y, z)=> {

          gsap.to(this.threeJSBackground.three.camera.position, {
            x,
            y,
            z,
            duration: 3
          });
        }

        rotateCamera(
          Math.random(),
          Math.random(),
          Math.random()
        )
        moveCamera(
          generateRandomNumber(-300,300),
          generateRandomNumber(-300,300),
          generateRandomNumber(-300,300)
        )
      })
    })


  }

  desktopNavZero = (() => {
    let mainDropdowns = this.createDesktopNavZero();
    this.setActionsForDesktopNavZeroOptions(mainDropdowns)
    this.addIdsToDesktopNavZeroOptions(mainDropdowns);

    return mainDropdowns;
  })();




  getOptions(dropdowns: WMLInfiniteDropdownZeroProps[],makeResultSet= false) {
    return dropdowns
      .map((dropdown) => {
        if(makeResultSet){
          return {
            dropdown,
            option:dropdown.options[0]
          }
        }
        return dropdown.options[0]
      }) as any;
  }
}
