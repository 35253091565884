// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';


// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLUIProperty, generateClassPrefix, replaceValuesWithPaths } from '@windmillcode/wml-components-base';


// misc

import { WMLOptionZeroItemProps, WMLOptionsZeroProps } from '@windmillcode/angular-wml-options';
import enTranslations from "src/assets/i18n/en.json";


@Component({
    selector: 'confirm-dialog-zero',
    templateUrl: './confirm-dialog-zero.component.html',
    styleUrls: ['./confirm-dialog-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmDialogZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('ConfirmDialogZero')
  @Input('props') props: ConfirmDialogZeroProps = new ConfirmDialogZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  ngUnsub= new Subject<void>()

  ngOnInit(): void {
    if(this.props.type === ConfirmDialogZeroTypeProps.DEFAULT){
      this.props.options.options[1].click =()=>{
        this.baseService.closePopup()
      }
    }
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}


export enum ConfirmDialogZeroTypeProps{
  DEFAULT,CUSTOM
}
export class ConfirmDialogZeroProps {
  constructor(props:Partial<ConfirmDialogZeroProps &{propOptionIds:Array<string>,propYesAction:Function,propTitle:string,propSubTitle:string} >={}){
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });

    (props.propOptionIds ??[])
    .map((id,index0)=>{
      this.options.options[index0].id = id
    })

    if(props.propYesAction){
      this.updateYesAction(props.propYesAction)
    }
    if(props.propTitle){
      this.title.text = props.propTitle
    }
    if(props.propSubTitle){
      this.subtitle.text = props.propSubTitle
    }

  }
  updateYesAction =(value)=>{
    this.options.options[0].click = value
  }
  type = ConfirmDialogZeroTypeProps.DEFAULT
  title = new WMLUIProperty({
    text:"ConfirmDialogZero.title"
  })
  subtitle = new WMLUIProperty({

  })
  options = new WMLOptionsZeroProps({
    options: replaceValuesWithPaths(
      enTranslations.ConfirmDialogZero.options,
      'ConfirmDialogZero.options.'
    )
    // @ts-ignore
    .map((val)=>{
      return new WMLOptionZeroItemProps({
        text:val,
      })
    })
  })
}

