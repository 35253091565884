// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,Input} from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';



@Component({
    selector: 'capability-statement-zero',
    templateUrl: './capability-statement-zero.component.html',
    styleUrls: ['./capability-statement-zero.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CapabilityStatementZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('CapabilityStatementZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.capabilityStatementZero)
  @Input('props') props: CapabilityStatementZeroProps = new CapabilityStatementZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`)
  @HostBinding('attr.id') myId?:string = this.idPrefix()
  ngUnsub= new Subject<void>()


  listenForUpdate = ()=>{
    return this.props.setStateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new CapabilityStatementZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }


  ngOnInit(): void {
    this.listenForUpdate().subscribe()
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



@WMLConstructorDecorator
export class CapabilityStatementZeroProps {
  constructor(props:Partial<CapabilityStatementZeroProps>={}){ }

  setStateSubj = new Subject<CapabilityStatementZeroProps>()
  setState = (value)=>{
    this.setStateSubj.next(value)
  }
}


