import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';

import { UtilityService } from '@app/core/utility/utility.service';
import { Subject } from 'rxjs';

// i18n

@Component({
    selector: 'sample-cpnt',
    templateUrl: './sample-cpnt.component.html',
    styleUrls: ['./sample-cpnt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SampleCpntComponent implements OnInit {

  constructor(
    private cdref:ChangeDetectorRef,
    private utilService:UtilityService,
  ) { }
  @HostBinding('class') myClass: string = `View`;
  ngUnsub= new Subject<void>()
  amount=Array(4).fill(null)




  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }


}


export class SampleCpntMeta{
  constructor(props:Partial<SampleCpntMeta>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
  }
}
