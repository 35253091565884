import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { NavService } from '../services/nav/nav.service';


export const threejsNavGuard: CanMatchFn = (route, segments) => {

  let navService = inject(NavService);
  let router = inject(Router);
  if(!navService.threeJSBackground.three) {
    return true
  }
  // TODO should ignore the event but it navs to 404
  // if(["closing","opening","open"].includes(navService.landingPageMotionUIProperty.motionState)) {
  //   return false
  // }
  navService.landingPageMotionUIProperty.style.pointerEvents ="none"
  navService.landingPageMotionUIProperty.openMotion()

  return navService.performThreeJSNav()
  .pipe(
    map(()=>{
      navService.landingPageMotionUIProperty.style.pointerEvents ="auto"
      navService.landingPageMotionUIProperty.closeMotion()
      return true
    })
  )

};
