import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, HostBinding, Input, ViewContainerRef, ViewChild, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, delay, tap } from 'rxjs/operators';
import { generateClassPrefix, WMLWrapper, WMLCustomComponent } from '@windmillcode/wml-components-base';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@windmillcode/angular-wml-components-base';
import { addCustomComponent, WMLNGXTranslatePipe, WMLNGXTranslateMockPipe, WMLNGXTranslateLoader } from '@windmillcode/angular-wml-components-base';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import * as i1$1 from '@ngx-translate/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// angular
const _c0 = a0 => ({
  $implicit: a0
});
const _c1 = (a0, a1) => ({
  $implicit: a0,
  index: a1
});
function WMLLabelZeroComponent_div_0_label_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 4);
  }
  if (rf & 2) {
    const label_r1 = ctx.$implicit;
    i0.ɵɵnextContext(3);
    const templateLabelPart_r2 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngTemplateOutlet", templateLabelPart_r2)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, label_r1));
  }
}
function WMLLabelZeroComponent_div_0_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtemplate(1, WMLLabelZeroComponent_div_0_label_1_ng_container_1_Template, 1, 4, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPodLabel0"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.props.labels[0]);
  }
}
function WMLLabelZeroComponent_div_0_p_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 4);
  }
  if (rf & 2) {
    const label_r4 = ctx.$implicit;
    const index_r5 = i0.ɵɵnextContext().index;
    i0.ɵɵnextContext(2);
    const templateLabelPart_r2 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngTemplateOutlet", templateLabelPart_r2)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c1, label_r4, index_r5));
  }
}
function WMLLabelZeroComponent_div_0_p_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtemplate(1, WMLLabelZeroComponent_div_0_p_2_ng_container_1_Template, 1, 5, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const labelLine_r6 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPodLabel0"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", labelLine_r6);
  }
}
function WMLLabelZeroComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, WMLLabelZeroComponent_div_0_label_1_Template, 2, 3, "label", 1)(2, WMLLabelZeroComponent_div_0_p_2_Template, 2, 3, "p", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPod"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.props.type === "label");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.props.type === "label" ? ctx_r2.props.labels.splice(1) : ctx_r2.props.labels);
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_p_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const data_r7 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPodError0"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 3, data_r7.value));
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_p_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const index_r8 = i0.ɵɵnextContext(2).index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPodError0"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 3, ctx_r2.displayErrors[index_r8]));
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_a_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const index_r8 = i0.ɵɵnextContext(2).index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.classPrefix("MainPodError0"));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 3, ctx_r2.displayErrors[index_r8]));
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const data_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, data_r7.value));
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_a_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const data_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, data_r7.value));
  }
}
function WMLLabelZeroComponent_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, WMLLabelZeroComponent_ng_template_1_ng_container_0_p_1_Template, 3, 5, "p", 1)(2, WMLLabelZeroComponent_ng_template_1_ng_container_0_p_2_Template, 3, 5, "p", 1)(3, WMLLabelZeroComponent_ng_template_1_ng_container_0_a_3_Template, 3, 5, "a", 1)(4, WMLLabelZeroComponent_ng_template_1_ng_container_0_p_4_Template, 3, 3, "p", 5)(5, WMLLabelZeroComponent_ng_template_1_ng_container_0_a_5_Template, 3, 3, "a", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext();
    const data_r7 = ctx_r8.$implicit;
    const index_r8 = ctx_r8.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", data_r7.type === "required");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", data_r7.type === "error" && ctx_r2.formControl.errors && ctx_r2.formControl.dirty && ctx_r2.displayErrors[index_r8]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", data_r7.type === "errorLink" && ctx_r2.formControl.errors && ctx_r2.formControl.dirty && ctx_r2.displayErrors[index_r8]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", data_r7.type === "default");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", data_r7.type === "defaultLink");
  }
}
function WMLLabelZeroComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, WMLLabelZeroComponent_ng_template_1_ng_container_0_Template, 6, 5, "ng-container", 5);
  }
  if (rf & 2) {
    const data_r7 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", data_r7.isPresent);
  }
}
const _c2 = ["customLabel"];
const _c3 = ["customField"];
const _c4 = ["customError"];
function WMLFieldZeroPropsComponent_ng_template_2_Template(rf, ctx) {}
function WMLFieldZeroPropsComponent_ng_template_5_Template(rf, ctx) {}
function WMLFieldZeroPropsComponent_ng_template_8_Template(rf, ctx) {}
class WMLLabelZeroComponent {
  constructor(cdref) {
    this.cdref = cdref;
    this.classPrefix = generateClassPrefix('WMLLabel');
    this.myClass = this.classPrefix(`View`);
    this.ngUnsub = new Subject();
    this.displayErrors = [];
  }
  ngOnInit() {
    this.formControl = this.props.wmlField.field.parentForm.controls[this.props.wmlField.field.formControlName];
    this.listenForParentFormStatusChanges().subscribe();
    this.props.cdref = this.cdref;
  }
  listenForParentFormStatusChanges() {
    return this.props.wmlField.field.parentForm.statusChanges.pipe(takeUntil(this.ngUnsub), delay(100), tap(res => {
      this.displayErrors = this.props.toggleErrors(this.formControl);
      this.cdref.detectChanges();
    }));
  }
  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
  static {
    this.ɵfac = function WMLLabelZeroComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WMLLabelZeroComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WMLLabelZeroComponent,
      selectors: [["wml-label-zero"]],
      hostVars: 2,
      hostBindings: function WMLLabelZeroComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.myClass);
        }
      },
      inputs: {
        props: "props"
      },
      standalone: false,
      decls: 3,
      vars: 1,
      consts: [["templateLabelPart", ""], [3, "class", 4, "ngIf"], [3, "class", 4, "ngFor", "ngForOf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngFor", "ngForOf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngIf"]],
      template: function WMLLabelZeroComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, WMLLabelZeroComponent_div_0_Template, 3, 4, "div", 1)(1, WMLLabelZeroComponent_ng_template_1_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.props.isPresent);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i2.WMLNGXTranslatePipe],
      styles: ["[_nghost-%COMP%]   *[_ngcontent-%COMP%]{margin:0}.WMLLabelView[_nghost-%COMP%]   .WMLLabelMainPodLabel0[_ngcontent-%COMP%]{display:flex;color:#000}.WMLLabelView[_nghost-%COMP%]   .WMLLabelMainPodLabel0[_ngcontent-%COMP%]   *[_ngcontent-%COMP%]:not(:first-child){margin:0 0 0 .25rem}.WMLLabelView[_nghost-%COMP%]   .WMLLabelMainPodError0[_ngcontent-%COMP%]{color:#dc3545}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLLabelZeroComponent, [{
    type: Component,
    args: [{
      selector: 'wml-label-zero',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: false,
      template: "<div [class]=\"classPrefix('MainPod')\" *ngIf=\"props.isPresent\">\r\n  <label [class]=\"classPrefix('MainPodLabel0')\" *ngIf=\"props.type === 'label'\">\r\n    <ng-container\r\n    [ngTemplateOutlet]=\"templateLabelPart\"\r\n    [ngTemplateOutletContext]=\"{ $implicit: label }\"\r\n    *ngFor=\"let label of props.labels[0]\">\r\n\r\n    </ng-container>\r\n  </label>\r\n  <p [class]=\"classPrefix('MainPodLabel0')\" *ngFor=\" let labelLine of (  props.type === 'label' ?   props.labels.splice(1) : props.labels );let index = index  \">\r\n    <ng-container\r\n    [ngTemplateOutlet]=\"templateLabelPart\"\r\n    [ngTemplateOutletContext]=\"{ $implicit: label,index:index }\"\r\n    *ngFor=\"let label of labelLine\">\r\n\r\n    </ng-container>\r\n  </p>\r\n</div>\r\n\r\n\r\n<ng-template #templateLabelPart let-data let-index=\"index\">\r\n  <ng-container *ngIf=\"data.isPresent\">\r\n    <p *ngIf=\"data.type === 'required'\" [class]=\"classPrefix('MainPodError0')\"  >{{data.value | translate}}</p>\r\n    <p *ngIf=\"data.type === 'error'     && formControl.errors &&  formControl.dirty && displayErrors[index]  \" [class]=\"classPrefix('MainPodError0')\"  >{{displayErrors[index] | translate}}</p>\r\n    <a *ngIf=\"data.type === 'errorLink' && formControl.errors &&  formControl.dirty && displayErrors[index] \" [class]=\"classPrefix('MainPodError0')\"  >{{displayErrors[index] | translate}}</a>\r\n    <p *ngIf=\"data.type === 'default'\">{{data.value | translate}}</p>\r\n    <a *ngIf=\"data.type === 'defaultLink'\">{{data.value | translate}}</a>\r\n  </ng-container>\r\n\r\n</ng-template>\r\n",
      styles: [":host *{margin:0}:host.WMLLabelView .WMLLabelMainPodLabel0{display:flex;color:#000}:host.WMLLabelView .WMLLabelMainPodLabel0 *:not(:first-child){margin:0 0 0 .25rem}:host.WMLLabelView .WMLLabelMainPodError0{color:#dc3545}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    myClass: [{
      type: HostBinding,
      args: ['class']
    }],
    props: [{
      type: Input,
      args: ['props']
    }]
  });
})();
class WMLLabelZeroProps extends WMLWrapper {
  constructor(props = {}) {
    super(props);
    this.type = 'label';
    this.isPresent = true;
    this.errorMsgs = {};
    this.labels = [[{
      type: "required",
      value: "*"
    }, {
      type: "default",
      value: "My Label"
    }]];
    Object.assign(this, {
      ...props
    });
    this.labels = this.labels.map(labelLine => {
      return labelLine.map(label => {
        label.type = label.type || 'default';
        label.isPresent = label.isPresent === undefined ? true : label.isPresent;
        return label;
      });
    });
  }
  toggleErrors(formControl) {
    return Object.keys(formControl.errors ?? {}).map(key => {
      return this.errorMsgs[key];
    });
  }
}
class WMLFieldZeroPropsComponent {
  constructor(cdref) {
    this.cdref = cdref;
    this.classPrefix = generateClassPrefix('WMLField');
    this.wmlField = new WMLFieldZeroProps();
    this.myClass = this.classPrefix(`View`);
    this.ngUnsub = new Subject();
  }
  initComponent() {
    if (this.wmlField) {
      this.wmlField.view.cdref = this.cdref;
    }
    ["label", "field", "error"].forEach((key, index0) => {
      if (this.wmlField?.[key]?.type === "custom") {
        this.wmlField[key].custom.props.wmlField = this.wmlField;
        addCustomComponent([this.customLabel, this.customField, this.customError][index0], this.wmlField[key].custom.cpnt, this.wmlField[key].custom.props);
      }
    });
  }
  ngOnInit() {
    this.initComponent();
    this.myId = this.wmlField.view.id;
  }
  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
  static {
    this.ɵfac = function WMLFieldZeroPropsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WMLFieldZeroPropsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WMLFieldZeroPropsComponent,
      selectors: [["wml-field-zero"]],
      viewQuery: function WMLFieldZeroPropsComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 7, ViewContainerRef);
          i0.ɵɵviewQuery(_c3, 7, ViewContainerRef);
          i0.ɵɵviewQuery(_c4, 7, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customLabel = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customField = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customError = _t.first);
        }
      },
      hostVars: 3,
      hostBindings: function WMLFieldZeroPropsComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("id", ctx.myId);
          i0.ɵɵclassMap(ctx.myClass);
        }
      },
      inputs: {
        wmlField: [0, "props", "wmlField"]
      },
      standalone: false,
      decls: 10,
      vars: 8,
      consts: [["customLabel", ""], ["customField", ""], ["customError", ""]],
      template: function WMLFieldZeroPropsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div")(1, "section");
          i0.ɵɵtemplate(2, WMLFieldZeroPropsComponent_ng_template_2_Template, 0, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "section");
          i0.ɵɵtemplate(5, WMLFieldZeroPropsComponent_ng_template_5_Template, 0, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "section");
          i0.ɵɵtemplate(8, WMLFieldZeroPropsComponent_ng_template_8_Template, 0, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.classPrefix("MainPod"));
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.classPrefix("Pod0"));
          i0.ɵɵadvance(3);
          i0.ɵɵclassMap(ctx.classPrefix("Pod1"));
          i0.ɵɵadvance(3);
          i0.ɵɵclassMap(ctx.classPrefix("Pod2"));
        }
      },
      styles: [".WMLFieldView[_nghost-%COMP%]   .WMLFieldPod2Label0[_ngcontent-%COMP%]{color:red}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLFieldZeroPropsComponent, [{
    type: Component,
    args: [{
      selector: 'wml-field-zero',
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: false,
      template: "<div [class]=\"classPrefix('MainPod')\">\r\n  <section [class]=\"classPrefix('Pod0')\">\r\n    <ng-template #customLabel ></ng-template>\r\n  </section>\r\n  <section [class]=\"classPrefix('Pod1')\">\r\n    <ng-template #customField ></ng-template>\r\n  </section>\r\n  <section [class]=\"classPrefix('Pod2')\">\r\n    <ng-template #customError ></ng-template>\r\n  </section>\r\n</div>\r\n",
      styles: [":host.WMLFieldView .WMLFieldPod2Label0{color:red}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    wmlField: [{
      type: Input,
      args: ["props"]
    }],
    myClass: [{
      type: HostBinding,
      args: ['class']
    }],
    myId: [{
      type: HostBinding,
      args: ['attr.id']
    }],
    customLabel: [{
      type: ViewChild,
      args: ["customLabel", {
        read: ViewContainerRef,
        static: true
      }]
    }],
    customField: [{
      type: ViewChild,
      args: ["customField", {
        read: ViewContainerRef,
        static: true
      }]
    }],
    customError: [{
      type: ViewChild,
      args: ["customError", {
        read: ViewContainerRef,
        static: true
      }]
    }]
  });
})();
class WMLFieldZeroProps extends WMLWrapper {
  constructor(props = {
    type: "default",
    default: {},
    custom: {
      selfType: "wml-card"
    }
  }) {
    super();
    this.self = {
      type: "wml-card"
    };
    this.label = {
      type: "custom",
      custom: new WMLCustomComponent({
        cpnt: WMLLabelZeroComponent,
        props: new WMLLabelZeroProps()
      })
    };
    this.field = {
      type: "custom",
      custom: new WMLCustomComponent({
        cpnt: SampleCpntComponent,
        props: {}
      }),
      parentForm: new FormGroup({
        name: new FormControl()
      }),
      formControlName: "name"
    };
    this.error = {
      type: "custom",
      custom: new WMLCustomComponent({
        cpnt: WMLLabelZeroComponent,
        props: new WMLLabelZeroProps({
          errorMsgs: {
            required: "This field is required"
          },
          type: "error",
          labels: [[{
            type: "error",
            value: "Please resolve the above errors"
          }]]
        })
      })
    };
    this.getFieldProps = () => {
      return this.field.custom.props;
    };
    this.getReactiveFormControl = () => {
      return this.field.parentForm?.controls[this.field.formControlName] ?? this.field.formControl;
    };
    this.getParentForm = () => {
      return this.field.parentForm;
    };
    this.getFormControlName = () => {
      return this.field.formControlName;
    };
    this.getLabel = () => {
      return this.getLabelPart([0, 0]);
    };
    this.getRequiredLabel = () => {
      return this.getLabelPart();
    };
    this.getLabelPart = ([labelPart, part] = [0, 1]) => {
      return this.label.custom.props.labels[labelPart][part];
    };
    this.updateLabel = label => {
      this.updateLabelPart(label, [0, 0]);
    };
    this.updateRequiredLabel = label => {
      this.updateLabelPart(label);
    };
    this.updateLabelPart = (label, [labelPart, part] = [0, 1]) => {
      this.label.custom.props.labels[labelPart][part].value = label;
    };
    this.deleteLabelPart = ([labelPart, part] = [0, 0]) => {
      this.label.custom.props.labels[labelPart].splice(part, part + 1);
    };
    this.deleteRequiredLabelPart = () => {
      this.deleteLabelPart();
    };
    this.deleteLabel = () => {
      this.deleteLabelPart();
      this.deleteLabelPart();
    };
    if (props.type === "default") {
      Object.assign(this, {
        ...props.default
      });
    } else if (props.type === "custom") {
      let custom = props.custom ?? {};
      let labelWMLLabelZeroProps = this.label.custom.props;
      let errorWMLFieldErrorprops = this.error.custom.props;
      this.self.type = custom.selfType ?? "standalone";
      this.field.type = custom.fieldType ?? "custom";
      this.field.custom.cpnt = custom.fieldCustomCpnt ?? SampleCpntComponent;
      this.field.custom.props = custom.fieldCustomProps;
      this.field.parentForm = custom.fieldParentForm ?? this.field.parentForm;
      this.field.formControlName = custom.fieldFormControlName ?? this.field.formControlName;
      this.label.custom.cpnt = custom.labelCpnt ?? this.label.custom.cpnt;
      labelWMLLabelZeroProps.labels[0][1].value = custom.labelValue ?? labelWMLLabelZeroProps.labels[0][1].value;
      if (custom.labelRequired === false) {
        labelWMLLabelZeroProps.labels[0].shift();
      }
      this.error.custom.cpnt = custom.errorCpnt ?? this.error.custom.cpnt;
      errorWMLFieldErrorprops.errorMsgs = custom.errorMsgs ?? errorWMLFieldErrorprops.errorMsgs;
    }
  }
}
class SampleCpntComponent {
  static {
    this.ɵfac = function SampleCpntComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SampleCpntComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SampleCpntComponent,
      selectors: [["ng-component"]],
      standalone: false,
      decls: 2,
      vars: 2,
      template: function SampleCpntComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p");
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate2(" please install wml-input and pass the component and props class like so to the wmlfield .custom constructor fieldCustomCpnt : WMLInputZeroComponent, fieldCustomProps : new WMLInputZeroProps(\"", "{", "\"+ wmlField:this + \"", "}", "\") This was meant to be used in wml-form so now you understand the example create your own form field component with the appropriate props and use accordingly ");
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SampleCpntComponent, [{
    type: Component,
    args: [{
      template: `
    <p> please install wml-input and pass
    the component and props class like so to the wmlfield .custom constructor
    fieldCustomCpnt : WMLInputZeroComponent,
    fieldCustomProps : new WMLInputZeroProps("{{ '{' }}"+ wmlField:this + "{{ '}' }}")
    This was meant to be used in wml-form so now you understand the example
    create your own form field component with the appropriate props and use accordingly
    </p>
  `,
      standalone: false
    }]
  }], null, null);
})();
let cpnts = [WMLFieldZeroPropsComponent, WMLLabelZeroComponent];
class WMLFieldZeroNGXTranslateModule {
  static {
    this.ɵfac = function WMLFieldZeroNGXTranslateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WMLFieldZeroNGXTranslateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WMLFieldZeroNGXTranslateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: WMLNGXTranslatePipe,
        useValue: WMLNGXTranslateMockPipe
      }],
      imports: [CommonModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLFieldZeroNGXTranslateModule, [{
    type: NgModule,
    args: [{
      declarations: [...cpnts],
      imports: [WMLNGXTranslatePipe, CommonModule, ReactiveFormsModule],
      exports: [...cpnts],
      providers: [{
        provide: WMLNGXTranslatePipe,
        useValue: WMLNGXTranslateMockPipe
      }]
    }]
  }], null, null);
})();
class WMLFieldZeroModule {
  static {
    this.ɵfac = function WMLFieldZeroModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WMLFieldZeroModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WMLFieldZeroModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: () => new WMLNGXTranslateLoader()
        }
      }), WMLFieldZeroNGXTranslateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WMLFieldZeroModule, [{
    type: NgModule,
    args: [{
      imports: [TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: () => new WMLNGXTranslateLoader()
        }
      })],
      exports: [WMLFieldZeroNGXTranslateModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of wml-field
 */

/**
 * Generated bundle index. Do not edit.
 */

export { WMLFieldZeroModule, WMLFieldZeroNGXTranslateModule, WMLFieldZeroProps, WMLFieldZeroPropsComponent, WMLLabelZeroComponent, WMLLabelZeroProps };
