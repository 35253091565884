import { __decorate } from 'tslib';
import { getGlobalObject, WMLConstructorDecorator } from '@windmillcode/wml-components-base';
import { WebGLRenderer, Scene, AmbientLight, Clock, PerspectiveCamera, DirectionalLight, DirectionalLightHelper, PointLight, PointLightHelper, SpotLight, SpotLightHelper, HemisphereLight, HemisphereLightHelper, CameraHelper, OrthographicCamera, Vector3, Mesh, LoadingManager, Raycaster, Vector2 } from 'three';
import { GUI } from 'dat.gui';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
var WMLThreeCommonProps_1;
let WMLThreeProps = class WMLThreeProps {
  constructor(params = {}) {
    this.renderers = [new WebGLRenderer({
      antialias: true
    })];
    this.rendererParentElement = getGlobalObject().document.body;
    // TODO Array<Object3D> was there for a reason why
    this.scenes = [new Scene()];
    this.cameras = [];
    this.controls = [];
    this.inspectors = [];
    this.lights = [new WMLThreeLightProps({
      light: new AmbientLight(0xFFFFFF, 1)
    })];
    this.rayCasters = [new WMLThreeRayCasterProps()];
    this.objects = [];
    this.clock = new Clock();
    this.animateFunctions = [];
    // init methods
    this.init = async props => {
      let {
        preCheck,
        initRenderers,
        initCameras,
        initControls,
        initInspectors,
        initLights,
        initRayCasters,
        initObjects,
        animate,
        listenForWindowResize
      } = props || {};
      if (preCheck !== false) this.preCheck();
      if (initRenderers !== false) this.initRenderers();
      if (initCameras !== false) this.initCameras(initCameras);
      if (initControls !== false) this.initControls();
      if (initLights !== false) this.initLights();
      if (initObjects !== false) await this.initObjects();
      if (initRayCasters !== false) this.initRayCasters();
      if (initInspectors !== false) this.initInspectors();
      if (animate !== false) this.getCurrentRenderer().setAnimationLoop(this.animate);
      if (listenForWindowResize !== false) this.listenForWindowResize();
    };
    this.preCheck = () => {
      let myGlobal = getGlobalObject();
      if (!myGlobal?.document) {
        throw new Error('Three.js cannot work in your program, because it requires the "document" global.Browser environments usually hold this in the global window variable');
      }
    };
    this.animate = () => {
      this.animateFunctions.forEach(fn => fn({
        clock: this.clock
      }));
      this.renderers.forEach(renderer => {
        renderer.render(this.getCurentScene(), this.getCurentCamera());
      });
    };
    this.initRenderers = () => {
      this.renderers.forEach(renderer => {
        let rect = this.getRendererParentDetails();
        renderer.setSize(rect.width, rect.height);
        if (renderer instanceof WebGLRenderer) {
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setClearColor(0x333333);
          renderer.shadowMap.enabled = true;
        }
        if (renderer instanceof CSS2DRenderer) {
          renderer.domElement.style.position = 'absolute';
          renderer.domElement.style.top = '0px';
          renderer.domElement.style.pointerEvents = 'none';
        }
        this.rendererParentElement.appendChild(renderer.domElement);
      });
    };
    this.initCameras = (props = {
      fieldOfViewAngle: 75,
      nearestObjectCanBeToCameraToBeSeen: 0.1,
      farthestObjectCanBeToCameraToBeSeen: 2000
    }) => {
      let {
        fieldOfViewAngle,
        nearestObjectCanBeToCameraToBeSeen,
        farthestObjectCanBeToCameraToBeSeen
      } = props;
      let rect = this.getRendererParentDetails();
      let camera = new PerspectiveCamera(fieldOfViewAngle, rect.width / rect.height, nearestObjectCanBeToCameraToBeSeen, farthestObjectCanBeToCameraToBeSeen);
      camera.position.set(5, 5, 5);
      this.cameras.push(camera);
    };
    this.initControls = () => {
      this.controls.push(new OrbitControls(this.getCurentCamera(), this.getCurrentRenderer().domElement));
    };
    this.initLights = () => {
      this.lights.forEach(lightInfo => {
        let {
          light,
          addHelper,
          helper,
          addShadowHelper,
          shadowHelper
        } = lightInfo;
        // helps with pixelated shadows
        if (light.shadow) {
          light.shadow.mapSize.width = light.shadow.mapSize.height = 1024;
        }
        //
        this.getCurentScene().add(light);
        if (addHelper) {
          if (light instanceof DirectionalLight) {
            lightInfo.helper = helper = new DirectionalLightHelper(light, 5);
          } else if (light instanceof PointLight) {
            lightInfo.helper = helper = new PointLightHelper(light, 5);
          } else if (light instanceof SpotLight) {
            lightInfo.helper = helper = new SpotLightHelper(light, 5);
          } else if (light instanceof HemisphereLight) {
            lightInfo.helper = helper = new HemisphereLightHelper(light, 5);
          } else {
            console.warn(`no helper for ${light.type}`);
          }
        }
        if (helper) {
          this.getCurentScene().add(helper);
        }
        if (addShadowHelper && light.castShadow && light.shadow) {
          lightInfo.shadowHelper = shadowHelper = new CameraHelper(light.shadow.camera);
        }
        if (shadowHelper) {
          this.getCurentScene().add(shadowHelper);
        }
      });
    };
    this.initObjects = () => {
      this.objects.forEach(object => {
        object.regularMeshes.forEach(mesh => {
          this.getCurentScene().add(mesh);
        });
      });
      return Promise.all(this.objects.map(object => {
        return Promise.all(object.textures.map(texture => {
          return Promise.all(texture.group.map((item, index0) => {
            let {
              loader,
              url,
              onLoad,
              onProgress,
              onError
            } = item;
            loader.manager = texture.manager;
            return new Promise((res, rej) => {
              if (loader instanceof DRACOLoader) {
                loader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.5.7/");
                loader.setDecoderConfig({
                  type: 'js'
                });
                let gtlfLoader = new GLTFLoader();
                gtlfLoader.setDRACOLoader(loader);
                item.loader = loader = gtlfLoader;
              }
              loader.load(url, data => {
                object.meshes[index0] = data;
                onLoad?.(data);
                res(data);
              }, onProgress, err => {
                onError?.(err);
                rej(err);
              });
            });
          }));
        }));
      }));
    };
    this.initInspectors = () => {
      this.inspectors.forEach(inspector => {
        if (inspector.gui instanceof GUI) {
          Object.entries(inspector.values).forEach(([key, val]) => {
            inspector.options = inspector.options ?? {};
            inspector.options[key] = val.value;
          });
          Object.entries(inspector.values).forEach(([key, val]) => {
            let control;
            if (['boolean'].includes(typeof val.value)) {
              control = inspector.gui.add(inspector.options, key);
            }
            if (['string'].includes(typeof val.value)) {
              control = inspector.gui.addColor(inspector.options, key);
            } else if (typeof val.value === 'number') {
              control = inspector.gui.add(inspector.options, key, val?.min, val?.max);
            }
            if (val?.onChange) {
              val?.onChange(val.value, true);
              control.onChange(val?.onChange);
            }
          });
        }
      });
    };
    this.initRayCasters = () => {
      this.rayCasters.forEach(item => {
        let {
          raycaster,
          mousePosition,
          intersectCallback
        } = item;
        getGlobalObject().addEventListener('pointermove', e => {
          item.hasMouseEnteredRenderer = true;
          let {
            width,
            height
          } = this.getRendererParentDetails();
          mousePosition.x = e.clientX / width * 2 - 1;
          mousePosition.y = -(e.clientY / height) * 2 + 1;
        });
        this.animateFunctions.push(() => {
          if (!item.hasMouseEnteredRenderer) return;
          raycaster.setFromCamera(mousePosition, this.getCurentCamera());
          let intersects = raycaster.intersectObjects(this.getCurentScene().children);
          intersectCallback(intersects);
        });
      });
    };
    this.listenForWindowResize = () => {
      getGlobalObject().addEventListener("resize", e => {
        const {
          height,
          width
        } = this.getRendererParentDetails();
        this.cameras.forEach(camera => {
          if ([PerspectiveCamera].some(cameraType => camera instanceof cameraType)) {
            camera.aspect = width / height;
          }
          if ([PerspectiveCamera, OrthographicCamera].some(cameraType => camera instanceof cameraType)) {
            camera.updateProjectionMatrix();
          }
        });
        this.renderers.forEach(renderer => {
          renderer.setSize(width, height);
        });
      });
    };
    //
    this.getRendererParentDetails = () => {
      if (this.rendererParentElement === document.body) {
        return {
          width: window.innerWidth,
          height: window.innerHeight
        };
      } else {
        let rect = this.rendererParentElement.getBoundingClientRect();
        return {
          width: rect.width,
          height: rect.height
        };
      }
    };
    this.getCurentScene = () => this.scenes[0];
    this.getCurentCamera = () => this.cameras[0];
    this.getCurrentRenderer = () => this.renderers[0];
    this.getCurrentControls = () => this.controls[0];
    this.getCurrentRayCaster = () => this.rayCasters[0];
  }
};
WMLThreeProps = __decorate([WMLConstructorDecorator], WMLThreeProps);
let WMLThreeCommonProps = class WMLThreeCommonProps extends WMLThreeProps {
  static {
    WMLThreeCommonProps_1 = this;
  }
  constructor(params = {}) {
    super();
    this.updateCameraPosition = (props = {
      position: new Vector3(),
      updateControls: true
    }) => {
      let {
        position,
        lookAt,
        updateControls
      } = props;
      this.camera.position.set(position.x, position.y, position.z);
      if (lookAt) {
        if (this.control instanceof OrbitControls && !WMLThreeCommonProps_1.warnings.usingCameraLookAtWithOrbitControls) {
          console.warn("cant use lookAt with OrbitControls");
          WMLThreeCommonProps_1.warnings.usingCameraLookAtWithOrbitControls = true;
        }
        this.camera.lookAt(lookAt.x, lookAt.y, lookAt.z);
      }
      if (updateControls !== false) {
        this.control?.update();
      }
    };
  }
  static {
    this.warnings = {
      usingCameraLookAtWithOrbitControls: false
    };
  }
  get scene() {
    return this.getCurentScene();
  }
  set scene(scene) {
    this.scenes[0] = scene;
  }
  get camera() {
    return this.getCurentCamera();
  }
  set camera(camera) {
    this.cameras[0] = camera;
  }
  get control() {
    return this.getCurrentControls();
  }
  set control(control) {
    this.controls[0] = control;
  }
  get renderer() {
    return this.getCurrentRenderer();
  }
  set renderer(renderer) {
    this.renderers[0] = renderer;
  }
};
WMLThreeCommonProps = WMLThreeCommonProps_1 = __decorate([WMLConstructorDecorator], WMLThreeCommonProps);
let WMLThreeObjectProps = class WMLThreeObjectProps {
  constructor(params = {}) {
    this.geometries = [];
    this.materials = [];
    this.meshes = [];
    this.textures = [];
  }
  get regularMeshes() {
    return this.meshes;
  }
  get gltfMeshes() {
    return this.meshes;
  }
  get css2dMeshes() {
    return this.meshes;
  }
  get instancedMeshes() {
    return this.meshes;
  }
};
WMLThreeObjectProps = __decorate([WMLConstructorDecorator], WMLThreeObjectProps);
let WMLThreeCommonObjectProps = class WMLThreeCommonObjectProps extends WMLThreeObjectProps {
  constructor(props = {}) {
    super(props);
    this.wmlInit = () => {
      if (!this.mesh && this.textures.length === 0) {
        this.mesh = this.createMesh(this.geometry, this.material);
      }
    };
    this.toggleShadow = props => {
      this.regularMesh.castShadow = props.cast ?? !this.regularMesh.castShadow;
      this.regularMesh.receiveShadow = props.receive ?? !this.regularMesh.receiveShadow;
    };
    /**
     * @description Meant for planes but you can still use for your convenience
    */
    this.makeModelLieFlat = () => {
      this.regularMesh.rotation.x = -0.5 * Math.PI;
    };
    this.createMesh = (geometry, material) => {
      return new Mesh(geometry, material);
    };
  }
  get geometry() {
    return this.geometries[0];
  }
  set geometry(geometry) {
    this.geometries[0] = geometry;
  }
  get material() {
    return this.materials[0];
  }
  set material(material) {
    this.materials[0] = material;
  }
  get mesh() {
    return this.meshes[0];
  }
  set mesh(mesh) {
    this.meshes[0] = mesh;
  }
  get regularMesh() {
    return this.meshes[0];
  }
  set regularMesh(mesh) {
    this.meshes[0] = mesh;
  }
  get gltfMesh() {
    return this.meshes[0];
  }
  set gltfMesh(mesh) {
    this.meshes[0] = mesh;
  }
  get css2dMesh() {
    return this.meshes[0];
  }
  set css2dMesh(mesh) {
    this.meshes[0] = mesh;
  }
  get instancedMesh() {
    return this.meshes[0];
  }
  set instancedMesh(meshes) {
    this.meshes[0] = meshes;
  }
  get texture() {
    return this.textures[0];
  }
  set texture(texture) {
    this.textures[0] = texture;
  }
};
WMLThreeCommonObjectProps = __decorate([WMLConstructorDecorator], WMLThreeCommonObjectProps);
let WMLThreeLightProps = class WMLThreeLightProps {
  constructor(params = {}) {
    this.addHelper = false;
    this.addShadowHelper = false;
    this.toggleShadow = props => {
      this.light.castShadow = props.cast ?? !this.light.castShadow;
      this.light.receiveShadow = props.receive ?? !this.light.receiveShadow;
    };
    this.updateCamera = () => {
      // @ts-ignore
      this.light.shadow?.camera?.updateProjectionMatrix();
      this.shadowHelper?.update();
    };
  }
};
WMLThreeLightProps = __decorate([WMLConstructorDecorator], WMLThreeLightProps);
let WMLThreeTexturesProps = class WMLThreeTexturesProps {
  constructor(params = {}) {
    this.manager = new LoadingManager();
    this.group = [];
  }
};
WMLThreeTexturesProps = __decorate([WMLConstructorDecorator], WMLThreeTexturesProps);
let WMLThreeRayCasterProps = class WMLThreeRayCasterProps {
  constructor(params = {}) {
    this.raycaster = new Raycaster();
    this.mousePosition = new Vector2();
    this.hasMouseEnteredRenderer = false;
    this.intersectCallback = intersects => {};
  }
};
WMLThreeRayCasterProps = __decorate([WMLConstructorDecorator], WMLThreeRayCasterProps);

/*
 * Public API Surface of three
 */
// export * from './lib/functions';

/**
 * Generated bundle index. Do not edit.
 */

export { WMLThreeCommonObjectProps, WMLThreeCommonProps, WMLThreeLightProps, WMLThreeObjectProps, WMLThreeProps, WMLThreeRayCasterProps, WMLThreeTexturesProps };
