import { ApplicationConfig, ErrorHandler, importProvidersFrom, provideAppInitializer } from '@angular/core';
import { Router, TitleStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ENV } from '@env/environment';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpLoaderFactory, I18NSEOStrategy, waitFori18nextToLoad } from '@core/utility/i18n-utils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { GlobalErrorHandler } from '@shared/errorhandlers/global-error-handler';
import { NewAzureAccessTokenInterceptor } from '@shared/interceptors/new-azure-access-token.interceptor';
import { XsrfInterceptor } from '@shared/interceptors/xsrf.interceptor';
import { findMaxArrayBuffer } from '@core/utility/file-utils';
import {init,browserTracingIntegration,replayIntegration,TraceService } from "@sentry/angular-ivy";

findMaxArrayBuffer()


gsap.registerPlugin(ScrollToPlugin);

if(!["DEV","TEST","DOCKER_DEV"].includes(ENV.type)){
  init({
    environment:"Angular_"+ENV.type.toUpperCase(),
    dsn: "https://a2cf217ba50c5fe74250d2e58c5a9edf@o4505122556215296.ingest.us.sentry.io/4507999509413888",
    integrations: [
      browserTracingIntegration({
        tracePropagationTargets: ["localhost", "https://ai-prompt-modifier.com","https://ui.preview.ai-prompt-modifier.com"],
      }),
      replayIntegration()
    ],
    // beforeSend

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ["DEV","TEST","DOCKER_DEV"].includes(ENV.type)? 0.0: 1.0,
  });

}

let routerProviders


if(ENV.app.shouldPerformInitialNavigate()){
  routerProviders = provideRouter(routes)
}
else{
  // routerProviders =provideRouter(routes, withDisabledInitialNavigation());
  routerProviders = provideRouter(routes)
}


export const appConfig: ApplicationConfig = {
  providers: [
    // provideExperimentalZonelessChangeDetection(),
    routerProviders,
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps:[HttpClient]
        }
      })
    ),
    // @ts-ignore
    provideAppInitializer(waitFori18nextToLoad),
    {provide:HTTP_INTERCEPTORS,useClass:NewAzureAccessTokenInterceptor,multi:true},
    {provide:HTTP_INTERCEPTORS,useClass:XsrfInterceptor,multi:true},
    {provide:TitleStrategy,useClass:I18NSEOStrategy },

    // Sentry
    {
      provide: ErrorHandler,
      useFactory:()=> new GlobalErrorHandler({
        showDialog: false
      }),
    },

    {
      provide: TraceService,
      deps: [Router],
    }
  ]
};
